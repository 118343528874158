
<template lang="html">
    <div class="component-wrapper">
        <Search></Search>

</div>
</template>

<script>
import Search from '@/components/home/Search.vue'

export default {
    components: {
        Search
    },
    data() {
        return {
        }
    },
    mounted() {

    },
}
</script>

<style lang="css">
    .component-wrapper {
        color: white;
    }

</style>
