<template>
<div class="home">
    <Home></Home>
</div>
</template>

<script>
import Home from '@/components/home/Home.vue'

export default {
    name: 'HomeView',
    components: {
        Home
    }
}
</script>
