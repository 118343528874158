<template lang="html">
    <div class="container-fluid">
        <div class="home-header-one-container col-xl-5 d-none d-xl-flex m-xl-1">
            <h1 class="home-header-one">Find your new favorite audio dramas</h1>
        </div>
            <div class="row homepage-top-row">
                <div class="col-xl-5 d-none d-xl-flex m-xl-1 home-header-container">
                    <h5 class="home-headers">Search for podcasts by what you find interesting</h5>
                </div>
                <div class="col-12 d-xl-none d-flex home-header-container">
                    <h5 class="home-headers small">What do you like in your audio dramas?</h5>
                </div>
                <div class="col-xl-6 d-none d-xl-flex m-xl-1 home-header-container">
                    <h5 class="home-headers right">Discover new podcasts by browsing collections</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-5 col-lg-12 m-xl-3">
                    <div class="container-fluid search-div">
                        <b-form @submit.prevent="getSearchResultsByKeywords">
                            <div class="row">
                                <div class="col-6">

                                </div>
                                <div class="find-by home-search" id="find-by-keywords">
                                    <div class="keyword-search d-flex align-items-center mb-2">
                                        <img fluid :src="require(`@/assets/img/search.png`)" class="small-text-icon">
                                        <p class="keyword-search-header">Search by topic or theme</p>
                                    </div>
                                    <b-input v-model="inputKeywords"
                                             placeholder="E.g. ghost, detective, wizard, funny">
                                    </b-input>
                                </div>
                                <div class="find-by" id="find-by-genre">
                                    <div id="search-checkboxes" class="row">
                                        <div class="col-6">
                                            <b-form-group label="Filter by genres" v-slot="{ ariaDescribedby }">
                                                <b-form-checkbox-group d="checkbox-group-1"
                                                                       v-model="selectedGenres"
                                                                       :options="options"
                                                                       :aria-describedby="ariaDescribedby"
                                                                       name="podcast">
                                                </b-form-checkbox-group>
                                            </b-form-group>
                                        </div>
                                        <div class="loading-div col-6">
                                            <div v-if="this.showLoading" class="spinner-border" role="status">
                                                <!-- <span class="sr-only">Loading...</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="canSearch ? 'none': 'disabled-click'" id="search-button-div">
                                    <button id="search-button" @click.prevent="getSearchResultsByKeywords">
                                        Search
                                    </button>
                                </div>
                            </div>
                        </b-form>
                    </div>
                </div>
                <div class="col-xl-6 m-xl-3 search-div d-none d-xl-block d-flex">
                    <div class="container-fluid">
                        <div class="row">
                            <h4 id="genre-header">Discover your new favorite podcasts</h4>
                            <div class="col-xl-6">
                                <a href="/collections" class="category-route">
                                    <div class="genre home-button mt-3">
                                        <img fluid rounded :src="require(`@/assets/img/collections.png`)" class="icon-with-text">
                                        <p>Collections</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xl-6">
                                <a href="/popular" class="category-route">
                                    <div class="genre home-button mt-3">
                                        <img fluid rounded :src="require(`@/assets/img/popular.png`)" class="icon-with-text">
                                        <p>Popular</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="!this.$store.state.isAuthenticated" class="container-fluid">
                        <div class="row mt-5 register-row">
                            <div class="col-6">
                                <p>Join the community!</p>
                                <a href="/register" class="category-route">
                                    <div class="genre home-button mt-3">
                                        <p id="sign-up-for-an-account">Sign up</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-6">
                                <p>Already a member?</p>
                                <a class="category-route">
                                    <div class="genre home-button mt-3" @click="$parent.$parent.$parent.showLogin =! $parent.$parent.$parent.showLogin">
                                        <p id="sign-up-for-an-account">Login</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 register-row" v-else-if="this.$store.state.isAuthenticated">
                        <p>Want to help build this community?</p>
                        <a href="/create-collection" class="category-route">
                            <div class="genre home-button mt-3">
                                <img fluid rounded :src="require(`@/assets/img/build.png`)" class="icon-with-text">
                                <p id="sign-up-for-an-account">Build a collection of audio dramas you like!</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        <div class="col-xl-5 col-lg-12 home-about d-none d-lg-block">
            <div class="container-fluid">
                <div class="row">

                </div>
            </div>
        </div>
        <div class="container-fluid lower-row">
            <div class="row d-none d-xl-flex">
                <div class="col-xl-5 m-xl-3 justify-content-center playlist-row">
                        <p class="home-header">Featured collections</p>
                        <div class="col-12 featured-playlists mt-2">
                            <a v-for="(playlist, index) in featuredPlaylists"
                                 class="single-playlist-link"
                                 :key="index"
                                 :href="`/collections/${playlist.id}`">
                                <div class="col-1 d-flex playlist-image-container">
                                    <div class="playlist-image" :style="getPlaylistImageStyle(playlist)">
                                        <p class="playlist-image-text" :style="getPlaylistImageFontStyle(playlist)">
                                            {{getPlaylistInitials(playlist.name)}}
                                        </p>
                                    </div>
                                </div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <!-- <p v-if="playlist.name.length > 20" class="playlist-name home small col-11">
                                            {{playlist.name.slice(0, 40)}}...
                                        </p> -->
                                        <p class="playlist-name home small col-11">
                                            {{playlist.name}}
                                        </p>
                                    </div>
                                    <div class="row d-lg-flex d-none">
                                        <!-- <small class="multiple-playlists-summary col-11" v-if="playlist.summary.length > 30">
                                            {{playlist.summary.slice(0, 30)}}...
                                        </small> -->
                                        <small class="multiple-playlists-summary col-11">
                                            {{playlist.summary}}
                                        </small>
                                    </div>
                                </div>
                            </a>
                        </div>
                </div>
                <div class="col-xl-6 m-xl-3">
                    <p class="home-header">{{this.$store.state.title}}
                    <div class="about-home-div mt-2">
                        <p id="about-home" class="mb-2">
                            {{this.$store.state.title}} is a catalogue with descriptions
                             of over 5000 audio dramas, i.e. fictional podcasts, that was
                             created with a simple goal in mind: To help you find new and exciting audio dramas
                             to listen to whenever you feel like it.
                        </p>
                        <p>On {{this.$store.state.title}} you can find:</p>
                        <ul><strong>
                            <li>Lists of similar podcasts to the ones you like the most</li>
                            <li>Audio dramas by genres, topics, themes and keywords</li>
                            <li>User-created collections with varying themes</li>
                            </strong>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-xl-none">
            <div class="col-12 mb-2 justify-content-center">
                <div>
                    <a href="/popular" class="category-route">
                        <div class="genre home-button mt-3">
                            <img fluid rounded :src="require(`@/assets/img/popular.png`)" class="icon-with-text">
                            <p>Popular</p>
                        </div>
                    </a>
                </div>
                <div>
                    <a href="/collections" class="category-route">
                        <div class="genre home-button mt-3">
                            <img fluid rounded :src="require(`@/assets/img/collections.png`)" class="icon-with-text">
                            <p>Collections</p>
                        </div>
                    </a>
                </div>
                <h1 class="mt-4" id="home-header">Featured collections</h1>
                <div class="featured-playlists mobile mt-3">
                    <a v-for="(playlist, index) in featuredPlaylists"
                         class="single-playlist-link"
                         :key="index"
                         :href="`/collections/${playlist.id}`">
                        <div class="col-1 d-flex playlist-image-container">
                            <div class="playlist-image" :style="getPlaylistImageStyle(playlist)">
                                <p class="playlist-image-text" :style="getPlaylistImageFontStyle(playlist)">
                                    {{getPlaylistInitials(playlist.name)}}
                                </p>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <p v-if="playlist.name.length > 20" class="playlist-name home small col-11">
                                    {{playlist.name.slice(0, 20)}}...
                                </p>
                                <p v-else class="playlist-name home small col-11">
                                    {{playlist.name}}
                                </p>
                            </div>
                            <div class="row">
                                <!-- <small class="multiple-playlists-summary col-11" v-if="playlist.summary.length > 30">
                                    {{playlist.summary.slice(0, 30)}}...
                                </small> -->
                                <small class="multiple-playlists-summary col-11">
                                    {{playlist.summary}}
                                </small>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedGenres: [],
            featuredPlaylists: [],
            featuredPlaylistsIds: [1, 2, 3],
            inputKeywords: '',
            inputName: '',
            offset: 0,
            options: [
                {
                    text: 'Comedy',
                    value: 'comedy_score'
                },
                {
                    text: 'Crime',
                    value: 'crime_score'
                },
                {
                    text: 'Fantasy',
                    value: 'fantasy_score'
                },
                {
                    text: 'Folklore',
                    value: 'folklore_score'
                },
                {
                    text: 'Horror',
                    value: 'horror_score'
                },
                {
                    text: 'Mystery',
                    value: 'mystery_score'
                },
                {
                    text: 'Sci-Fi',
                    value: 'sci_fi_score'
                },
                {
                    text: 'Thriller',
                    value: 'thriller_score'
                },
            ],
            showLoading: false
        }
    },
    mounted() {
        this.getFeaturedPlaylists()
    },
    computed: {
        searchKeywords() {
            return this.inputKeywords.replace(/,\s|,|\s/g, '+').replace(/\s/g, '+')
        },
        searchName() {
            return this.inputName.replace(/,?\s|,|\s/g, '+').replace(/\s/g, '+')
        },
        canSearch() {
            return this.inputKeywords.length !== 0 || this.selectedGenres.length !== 0
        }
    },
    methods: {
        getSearchResultsByKeywords() {
            this.showLoading = true;
            let genreString = ''
            for (let genre of this.selectedGenres) {
                genreString += `&${genre}__gte=12`
            }
            let query = `small-podcasts/?search=${this.searchKeywords}${genreString}&limit=20&offset=${this.offset}`
            this.$http.get(`${this.$store.state.endpoints.baseUrl}${query}`).then((response) => {
                this.$store.commit('addSearchData', response.data)
                this.$store.commit('addSearchQuery', this.inputKeywords)
                this.$router.push({
                    name: 'searchResultsRoute',
                    path: '/results',
                    params: {
                        searchResultsData: response.data,
                        search: query
                    },
                }).catch(err => {return err})
            })
        },
        getFeaturedPlaylists() {
            for (let p of this.featuredPlaylistsIds) {
                this.$http.get(`${this.$store.state.endpoints.baseUrl}playlists/${p}`).then((response) => {
                    this.featuredPlaylists.push(response.data)
                })
            }
        },
        getPlaylistInitials(name) {
            name = name.split(' ')
            let wordsToRemove = ['a', 'the', 'of', 'in', 'at', 'but', 'by', 'for', 'to', 'and']
            let filteredName = name.filter(word =>
                                !wordsToRemove.includes(word)
                                && !wordsToRemove.includes(word.toUpperCase())
                                && !wordsToRemove.includes(word.toLowerCase())
                                && !wordsToRemove.includes(word.slice(0,1) + word.slice(1, word.length-1)))
            filteredName = filteredName.join(' ')
            return filteredName.split(' ').map((n)=>n[0].toUpperCase()).join('').slice(0,3)

        },
        getPlaylistImageBackgroundColor(playlist) {
            let colors = ['#3b3b3b', '#3c2222', '#222c3c', '#223c2b', '#3a223c']
            let summaryLength = playlist.summary.length
            let index = summaryLength % colors.length
            return colors[index]
        },
        getPlaylistImageFontColor(playlist) {
            let colors = ['#7afafe', '#fe7a7a', '#84fe7a','#fbfe7a', '#807afe']
            let nameLength = playlist.name.length
            let index = nameLength % colors.length
            return colors[index]
        },
        getPlaylistImageStyle(playlist) {
            return `background:${this.getPlaylistImageBackgroundColor(playlist)};
                    margin-left: auto;
                    height: 3em;
                    width: 3em;
                    position: absolute;
                    left: 0em;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    border-radius: 0.2em;`
        },
        getPlaylistImageFontStyle(playlist) {
            return `align-self: center;
                    font-size: 1.3em;
                    color: #84fe7a;
                    font-weight: bolder;
                    cursor: default;
                    color:${this.getPlaylistImageFontColor(playlist)};`
        }
    }

}
</script>

<style lang="css" scoped>
    #search-checkboxes {
        text-align: left;
    }
    .loading-div {
        display: flex;
        align-items: center;
    }
    .search-div {
        min-height: 100%;
        border-radius: 1.2em;
        padding: 1em;
        box-shadow: var(--globalButtonShadow);
    }
    #search-button {
        background: var(--hoverColor);
        box-shadow: var(--globalButtonShadow);
        border-radius: 12px;
        width: 8em;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        align-self: center;
        padding: 0.5em;
        border: none;
    }
    #search-button:active {
        transform: translateY(4px);
    }
    #search-button:hover {
        -webkit-transition-duration: 0.2s; /* Safari */
        transition-duration: 0.2s;
        background: var(--hoverColor);
    }
    .home-button:active {
        transform: translateY(4px);
        -webkit-transition-duration: 0.2s; /* Safari */
        transition-duration: 0.2s;
    }
    .home-button:hover {
        -webkit-transition-duration: 0.2s; /* Safari */
        transition-duration: 0.2s;
        background: var(--hoverColor);
    }
    .home-button.small-button-home {
        width: 90%;
    }
    #search-button-div {
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
    }
    #search-text {
        margin: 0px;
    }
    #search-header {
        margin-bottom: 1em;
    }
    .genre {
        box-shadow: var(--globalButtonShadow);
        margin-bottom: 1em;
        height: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        cursor: pointer;
    }
    .playlist-row {
        padding-left: 1em !important;
    }
    .genre:active {
        background: var(--hoverColor);
        color: white;
    }
    .find-by {
        margin-bottom: 1em;
    }
    .find-by.home-search {
        width: 100%;
    }
    .disabled-click {
        pointer-events:none;
    }
    .sign-up-home-button {
        height: 200%;
    }
    .category-route {
        color: white !important;
        text-decoration: none !important;
    }
    .category-route.small-route {
        display: flex;
        justify-content: center;
    }
    .single-playlist-link:active {
        transform: translateY(4px);
    }
    .single-playlist-link:hover {
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        background: var(--hoverColor);
    }
    #genres-div {
        display: flex;
    }
    #genre-header {
        margin-bottom: 2em;
    }
    .custom-control-input {
        margin-right: 1em !important;
        border-radius: 5px;
    }
    .row.register-row {
        margin-bottom: auto !important;
    }
    #sign-up-for-an-account {
        font-weight: bolder;
    }
    .single-playlist-link {
        padding: 0.5em;
        display: flex;
        cursor: pointer;
        text-decoration: none;
        color: var(--darkBackground);
        border-radius: 20px;
        box-shadow: var(--globalButtonShadow);
        max-height: 6em;
        min-height: 6em;
        margin-bottom: 0.4em;
        background: #ffeb85;
    }
    .single-playlist-link:hover {
        background: var(--hoverColor);
        color: white;
    }
    .single-playlist-link p {
        text-decoration: none !important;
        font-size: 1.5em;
    }
    .featured-playlists a {
        text-decoration: none !important;
    }
    .about-home-div {
        margin-top: 0em;
        background: none;
        box-shadow: var(--globalInwardShadow);
        border-radius: 1.2em;
        color: white;
        padding: 1em;
        overflow-y: scroll;
        max-height: 33vh;
        scrollbar-width: none;
        padding: 1em;

    }
    .about-home-div::-webkit-scrollbar {
        display: none;
    }
    #about-home {
        margin-top: 0.4em;
    }
    .home-header {
        font-size: 1.5em;
    }
    .lower-row {
        padding: 0px !important;
    }
    .home-buttons-small {

    }
    .flex-gap {
        display: inline-flex;
    }
    .home-header-one {
        font-size: 1.2em;
        margin-bottom: 0px;
    }
    .icon-with-text {
        height: 1.5em;
        width: auto;
        left: 1em;
        position: absolute;
    }
    .genre.home-button {
        position: relative;
    }
    .playlist-image-container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: 0.2em;
    }
    .playlist-name.home.small {
        font-size: 1em;
        font-weight: bolder;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 0.4em;
        margin-left: 0.2em;
    }
    .playlist-name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 0.4em;
        margin-left: 0.2em;
    }
    .multiple-playlists-summary {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-left: 0.2em;
    }
    .home-headers {
        margin-bottom: 0px !important;
    }
    .home-headers.small {
        margin-bottom: 0.4em !important;
        font-size: 1em;
    }
    .small-text-icon {
        height: 1em;
        width: auto;
        margin-right: 0.4em;
    }
    .homepage-top-row {
        margin-left: 1em;
    }
    @media (max-width: 1199px) {
        .homepage-top-row {
            margin-left: 0px !important;
        }
        .homepage-top-row h5 {
            font-size: 1.1em;
        }
    }
    .home-header-one-container {
        box-shadow: var(--globalButtonShadow);
        padding: 0.8em;
        border-radius: 0.4em;
        margin-bottom: 2em;
        background: white;
        margin-left: 1.5em !important;
        width: 40%;
        color: var(--darkBackground);
    }
    .home-header-container {
        align-items: center;
    }
    .home-headers.right {
        margin-left: 1.2em;
    }
</style>
